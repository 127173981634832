<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilTags" />
          MISC
        </span>
        <CButton
          color="success"
          size="sm"
          class="float-right"
          @click="
            (e) => {
              miscId = 0;
              editing = true;
            }
          "
        >
          <CIcon name="cil-plus" />
          Thêm Value
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="4">
            <CSelect
              label="Loại"
              placeholder="Chọn Loại"
              :value.sync="miscType"
              :options="miscTypes"
              horizontal
            />
          </CCol>
        </CRow>
        <odata-table
          ref="lstMisc"
          url="odata/Misc"
          :filter="filter"
          select="Id,Value,Text,Note,Type,Status,OrderNo"
          sortBy="Type asc, OrderNo asc"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            OrderNo: {
              field: 'OrderNo',
              display: 'Thứ tự',
              sortable: true,
              style: 'min-width: 50px',
            },
            Value: {
              field: 'Value',
              display: 'Mã',
              sortable: true,
              style: 'min-width: 100px',
            },
            Text: {
              field: 'Text',
              display: 'Tiêu đề',
              sortable: true,
              style: 'min-width: 150px',
            },
            Note: {
              field: 'Note',
              display: 'Ghi chú',
              sortable: true,
              style: 'min-width: 150px',
            },
            Type: {
              field: 'Type',
              display: 'Loại',
              sortable: true,
              style: 'min-width: 100px',
            },
            Status: {
              field: 'Status',
              display: 'Trạng thái',
              sortable: true,
              style: 'min-width: 100px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-for="(row, i) in rows" :key="`tr-${i}`">
              <td></td>
              <td>
                {{ row.OrderNo }}
              </td>
              <td>{{ row.Value }}</td>
              <td>
                <a
                  href="javascript:"
                  class="text-primary"
                  :title="`Cập nhật: ${row.Text}`"
                  @click="
                    (e) => {
                      miscId = row.Id;
                      editing = true;
                    }
                  "
                >
                  <CIcon name="cil-pencil" />
                  {{ row.Text }}
                </a>
              </td>

              <td class="text-truncate" style="max-width:200px;">
                <span :title="row.Note">{{ row.Note }}</span>
              </td>
              <td>{{ row.Type }}</td>
              <td>
                <CBadge :color="getBadge(row.Status)">
                  {{ $const.STATUS_TEXT[row.Status] }}
                </CBadge>
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <detail
      :editing.sync="editing"
      :miscId.sync="miscId"
      :miscType="miscType"
      @reload="$refs.lstMisc.loadData()"
    />
  </div>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";

export default {
  name: "Misc",
  components: {
    odataTable,
    detail,
  },
  data() {
    return {
      editing: false,
      miscId: 0,
      pageSize: 10,
      miscType: this.$const.MISC_TYPES[0],
      miscTypes: this.$const.MISC_TYPES,
    };
  },
  computed: {
    filter() {
      let filters = [];

      this.miscType && filters.push(`Type eq '${this.miscType}'`);

      return filters.join(" and ");
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case this.$const.STATUS.Active:
          return "success";
        case this.$const.STATUS.InActive:
          return "secondary";
        default:
          "primary";
      }
    },
  },
};
</script>
