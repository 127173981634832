var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilTags"}}),_vm._v(" MISC ")],1),_c('CButton',{staticClass:"float-right",attrs:{"color":"success","size":"sm"},on:{"click":function (e) {
            _vm.miscId = 0;
            _vm.editing = true;
          }}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm Value ")],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"4"}},[_c('CSelect',{attrs:{"label":"Loại","placeholder":"Chọn Loại","value":_vm.miscType,"options":_vm.miscTypes,"horizontal":""},on:{"update:value":function($event){_vm.miscType=$event}}})],1)],1),_c('odata-table',{ref:"lstMisc",attrs:{"url":"odata/Misc","filter":_vm.filter,"select":"Id,Value,Text,Note,Type,Status,OrderNo","sortBy":"Type asc, OrderNo asc","pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          OrderNo: {
            field: 'OrderNo',
            display: 'Thứ tự',
            sortable: true,
            style: 'min-width: 50px',
          },
          Value: {
            field: 'Value',
            display: 'Mã',
            sortable: true,
            style: 'min-width: 100px',
          },
          Text: {
            field: 'Text',
            display: 'Tiêu đề',
            sortable: true,
            style: 'min-width: 150px',
          },
          Note: {
            field: 'Note',
            display: 'Ghi chú',
            sortable: true,
            style: 'min-width: 150px',
          },
          Type: {
            field: 'Type',
            display: 'Loại',
            sortable: true,
            style: 'min-width: 100px',
          },
          Status: {
            field: 'Status',
            display: 'Trạng thái',
            sortable: true,
            style: 'min-width: 100px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return _vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td'),_c('td',[_vm._v(" "+_vm._s(row.OrderNo)+" ")]),_c('td',[_vm._v(_vm._s(row.Value))]),_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":("Cập nhật: " + (row.Text))},on:{"click":function (e) {
                    _vm.miscId = row.Id;
                    _vm.editing = true;
                  }}},[_c('CIcon',{attrs:{"name":"cil-pencil"}}),_vm._v(" "+_vm._s(row.Text)+" ")],1)]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_c('span',{attrs:{"title":row.Note}},[_vm._v(_vm._s(row.Note))])]),_c('td',[_vm._v(_vm._s(row.Type))]),_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(row.Status)}},[_vm._v(" "+_vm._s(_vm.$const.STATUS_TEXT[row.Status])+" ")])],1)])})}}])})],1)],1),_c('detail',{attrs:{"editing":_vm.editing,"miscId":_vm.miscId,"miscType":_vm.miscType},on:{"update:editing":function($event){_vm.editing=$event},"update:miscId":function($event){_vm.miscId=$event},"update:misc-id":function($event){_vm.miscId=$event},"reload":function($event){return _vm.$refs.lstMisc.loadData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }