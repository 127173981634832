<template>
  <CModal
    :title="misc.Id ? 'Cập nhật Misc' : 'Thêm Misc'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
    size="lg"
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CRow>
          <CCol md="3">
            <CInput
              label="Mã*"
              placeholder="Mã"
              v-model="misc.Value"
              :add-input-classes="{
                'is-invalid': !!inValidObject['misc.Value'],
              }"
              :invalid-feedback="inValidObject['misc.Value']"
              :disabled="!!misc.Id"
            />
          </CCol>
          <CCol md="7">
            <CInput
              label="Tiêu đề*"
              placeholder="Tiêu đề"
              v-model="misc.Text"
              :add-input-classes="{
                'is-invalid': !!inValidObject['misc.Text'],
              }"
              :invalid-feedback="inValidObject['misc.Text']"
            />
          </CCol>
          <CCol md="2">
            <CInput
              type="number"
              label="Thứ tự*"
              placeholder="Thứ tự"
              v-model="misc.OrderNo"
              :add-input-classes="{
                'is-invalid': !!inValidObject['misc.OrderNo'],
              }"
              :invalid-feedback="inValidObject['misc.OrderNo']"
            />
          </CCol>
          <CCol md="3">
            <CSelect
              label="Trạng thái"
              placeholder="Chọn Trạng thái"
              :value.sync="misc.Status"
              :options="
                Object.keys($const.STATUS_TEXT).map((_) => {
                  return {
                    value: _,
                    label: $const.STATUS_TEXT[_],
                  };
                })
              "
              :add-input-classes="{
                'is-invalid': !!inValidObject['misc.Status'],
              }"
              :invalid-feedback="inValidObject['misc.Status']"
            />
          </CCol>
          <CCol md="9">
            <CInput
              label="Ghi chú"
              placeholder="Ghi chú"
              v-model="misc.Note"
              :add-input-classes="{
                'is-invalid': !!inValidObject['misc.Note'],
              }"
              :invalid-feedback="inValidObject['misc.Note']"
            />
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer>
      <CButton class="btn btn-primary" @click="saveMisc" :disabled="saving">
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>
<script>
export default {
  props: ["editing", "miscId", "miscType"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      misc: this.newMisc(),
      saving: false,
    };
  },
  watch: {
    async miscId(val) {
      await this.loadMiscInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:miscId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
    miscType() {
      this.misc = this.newMisc();
    },
  },
  methods: {
    newMisc() {
      return {
        Id: 0,
        Value: null,
        Text: null,
        Note: null,
        Type: this.miscType,
        Status: this.$const.STATUS.Active.toString(),
        OrderNo: 0,
      };
    },

    async loadMiscInfo(miscId) {
      try {
        this.inValidObject = {};
        if (miscId) {
          let filters = [];
          miscId && filters.push(`Id eq ${miscId}`);
          let resp = await this.$http.get(`odata/Misc`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select: "Id,Value,Text,Note,Type,Status,OrderNo",
            },
          });
          if (resp && resp.status == 200) {
            let _ = resp.data.value[0];
            this.misc = {
              Id: _.Id,
              Value: _.Value,
              Text: _.Text,
              Note: _.Note,
              Type: _.Type,
              Status: _.Status.toString(),
              OrderNo: _.OrderNo,
            };
          }
        } else {
          this.misc = this.newMisc();
        }
      } catch (error) {
        alert(error);
      }
    },

    async saveMisc() {
      let misc = this.misc;
      this.inValidObject = this.checkValid(misc);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let miscData = {
          Text: misc.Text,
          Note: misc.Note,
          Status: parseInt(misc.Status),
          OrderNo: misc.OrderNo,
        };
        // Misc
        if (!misc.Id) {
          // post
          miscData.Value = misc.Value;
          miscData.Type = misc.Type;
          let respCheck = await this.$http.get(`odata/Misc`, {
            params: {
              $top: 1,
              $filter: `Value eq '${miscData.Value}'`,
              $select: "Id",
            },
          });
          if (respCheck && respCheck.status == 200) {
            let respCheckData = respCheck.data.value;
            if (respCheckData.length) {
              alert(
                `Mã '${miscData.Value}' đã được sử dụng. Vui lòng nhập mã khác!`
              );
            } else {
              let resp = await this.$http.post(`odata/Misc`, miscData);
              if (resp && resp.status == 201) {
                this.detailModal = false;
                this.$emit("reload");
              }
            }
          }
        } else {
          // patch
          let resp = await this.$http.patch(`odata/Misc/${misc.Id}`, miscData);
          if (resp && resp.status == 204) {
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(misc) {
      let inValidObject = {};

      if (!misc.Value) inValidObject["misc.Value"] = "Vui lòng nhập Mã!";
      if (!misc.Text) inValidObject["misc.Text"] = "Vui lòng nhập Tiêu đề!";
      if (!misc.Status)
        inValidObject["misc.Status"] = "Vui lòng chọn Trạng thái!";
      if (!misc.OrderNo)
        inValidObject["misc.OrderNo"] = "Vui lòng nhập Thứ tự!";

      return inValidObject;
    },
  },
};
</script>
