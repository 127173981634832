var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.misc.Id ? 'Cập nhật Misc' : 'Thêm Misc',"show":_vm.detailModal,"closeOnBackdrop":false,"centered":"","size":"lg"},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":"Mã*","placeholder":"Mã","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['misc.Value'],
            },"invalid-feedback":_vm.inValidObject['misc.Value'],"disabled":!!_vm.misc.Id},model:{value:(_vm.misc.Value),callback:function ($$v) {_vm.$set(_vm.misc, "Value", $$v)},expression:"misc.Value"}})],1),_c('CCol',{attrs:{"md":"7"}},[_c('CInput',{attrs:{"label":"Tiêu đề*","placeholder":"Tiêu đề","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['misc.Text'],
            },"invalid-feedback":_vm.inValidObject['misc.Text']},model:{value:(_vm.misc.Text),callback:function ($$v) {_vm.$set(_vm.misc, "Text", $$v)},expression:"misc.Text"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"type":"number","label":"Thứ tự*","placeholder":"Thứ tự","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['misc.OrderNo'],
            },"invalid-feedback":_vm.inValidObject['misc.OrderNo']},model:{value:(_vm.misc.OrderNo),callback:function ($$v) {_vm.$set(_vm.misc, "OrderNo", $$v)},expression:"misc.OrderNo"}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Chọn Trạng thái","value":_vm.misc.Status,"options":Object.keys(_vm.$const.STATUS_TEXT).map(function (_) {
                return {
                  value: _,
                  label: _vm.$const.STATUS_TEXT[_],
                };
              }),"add-input-classes":{
              'is-invalid': !!_vm.inValidObject['misc.Status'],
            },"invalid-feedback":_vm.inValidObject['misc.Status']},on:{"update:value":function($event){return _vm.$set(_vm.misc, "Status", $event)}}})],1),_c('CCol',{attrs:{"md":"9"}},[_c('CInput',{attrs:{"label":"Ghi chú","placeholder":"Ghi chú","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['misc.Note'],
            },"invalid-feedback":_vm.inValidObject['misc.Note']},model:{value:(_vm.misc.Note),callback:function ($$v) {_vm.$set(_vm.misc, "Note", $$v)},expression:"misc.Note"}})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.saveMisc}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu thông tin ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }